// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wallet_dollarIcon__ZXGXn {
  transform: scale(0.7);
}

.wallet_availableBalanceText__F4Ekd {
   display: flex;
  line-height: 35px;
  font-size: 14px;
}

.wallet_ETHBalance__M7rcJ,
.wallet_MIPsBalance__0zodP {
  margin-left: 20%;
}

.wallet_balance__mxEIr {
  color: green;
  font-size: 12px;
}

.wallet_token__y\\+1Fl {
  font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/wallet.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;GACG,aAAa;EACd,iBAAiB;EACjB,eAAe;AACjB;;AAEA;;EAEE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".dollarIcon {\n  transform: scale(0.7);\n}\n\n.availableBalanceText {\n   display: flex;\n  line-height: 35px;\n  font-size: 14px;\n}\n\n.ETHBalance,\n.MIPsBalance {\n  margin-left: 20%;\n}\n\n.balance {\n  color: green;\n  font-size: 12px;\n}\n\n.token {\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dollarIcon": `wallet_dollarIcon__ZXGXn`,
	"availableBalanceText": `wallet_availableBalanceText__F4Ekd`,
	"ETHBalance": `wallet_ETHBalance__M7rcJ`,
	"MIPsBalance": `wallet_MIPsBalance__0zodP`,
	"balance": `wallet_balance__mxEIr`,
	"token": `wallet_token__y+1Fl`
};
export default ___CSS_LOADER_EXPORT___;
