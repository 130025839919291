import React, { useEffect, useState } from "react";
import MetaMaskSDK from '@metamask/sdk'
import { ethers } from 'ethers'

const MMSDK = new MetaMaskSDK()

const NFTmodal = ({
  nft,
  title,
  group_tag,
  price,
  lastSale,
  chain,
  contract_address,
  token_id,
}) => {

  const [networkName, setNetworkName] = useState()

  useEffect(() => {
    async function getProvider() {
      await MMSDK.connect()
      const ethereum = MMSDK.getProvider()
      const provider = new ethers.BrowserProvider(ethereum, 'sepolia')
      const network = await provider.getNetwork()

      setNetworkName(network.name)
    }

    getProvider()
  }, [])

  // Construct the OpenSea URL based on the NFT data
  const openSeaUrl = `${process.env.REACT_APP_OPENSEA_URL}assets/${networkName}/${process.env.REACT_APP_MIP_ADDRESS}/${token_id}`;

  return (
    <div
      className="nft_container"
      onClick={() => window.open(openSeaUrl, "_blank")}
      style={{ cursor: "pointer" }}
    >
      <div className="nft_image_container">
        <img src={nft} className="nft_url" alt={title} />
      </div>
      <div className="nft_description">
        <div className="nft_group">
          <div className="nft_title">{title}</div>
          <div className="nft_tag">{group_tag}</div>
        </div>
        <div className="nft_price">{price} ETH</div>
        <div className="nft_details">Last Sale: {lastSale} WETH</div>
      </div>
    </div>
  );
};

export default NFTmodal;
