import React from "react";
import Checkbox from "@mui/material/Checkbox";
import styles from './checkbox.module.css'

export const RememberMeCheckBox = props => {
  return (
    <div>
      <Checkbox className={styles.checkBox} onChange={props.onChange} />
      <label className={styles.CheckBoxTextLabel}>Remember me</label>
    </div>
  );
};

export const AgreeCheckBox = props => {
  return (
    <div>
      <Checkbox className={styles.checkBox} onChange={props.onChange}/>
      <label className={styles.CheckBoxTextLabel}>
        I agree to all the Terms and Privacy policy
      </label>
    </div>
  );
};
