import React, { useEffect, useState } from 'react'
import { redirect, useParams } from 'react-router-dom'
import TextInputForm from '../components/forms/textInput.tsx'
import { notifyError, notifySuccess } from '../hooks/notifications'

import styles from './resetPassword.module.css'

const ResetPassword = () => {
  const { id, resetToken } = useParams()
  const [user, setUser] = useState()
  const [updated, setUpdated] = useState()
  const [password1, setPassword1] = useState()
  const [password2, setPassword2] = useState()

  useEffect(() => {
    async function resetPassword (){

      const response = await fetch(`${process.env.REACT_APP_PUBLIC_API}/resetPassword`, {
        method: 'POST',
        body: JSON.stringify({
          id: +id,
          reset_token: resetToken,
        })
      })

      const data = await response.json()
      if (data.status != 'ok'){
        return notifyError('Unable to verify token', 'Error')
      }

      return { id, resetToken }
    }

    try {
      resetPassword()
        .then(setUser)
    } catch(e) {
      console.log('error verifying token: ', e)
      notifyError('Unable to verify token', 'Error')
    }
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()

    const msgs = []
    if (password1 && password1.length==0) {
      msgs.push('password is empty')
    } else if (password1!==password2) {
      msgs.push('Passwords mismatch')
    } else {
      const res = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(password1)
      if (!res) msgs.push('Password must be minimum 8 characters, 1 letter, 1 number and 1 special character')
    }
    if (msgs.length){
      msgs.forEach(msg => {
        notifyError(msg, 'The following errors were found')
      })

      return false
    }

    const response = await fetch(`${process.env.REACT_APP_PUBLIC_API}/resetPassword`, {
      method: 'POST',
      body: JSON.stringify({
        id: +id,
        password: password1,
        reset_token: resetToken,
      })
    })

    const data = await response.json()
    if (data.status != 'ok'){
      return notifyError('Unable to verify token', 'Error')
    }

    setUpdated(true)
    notifySuccess('Password Updated')
    redirect('/login')
  }

  return (
    <div>
      <h1>Resetting password</h1>
      { user ? (
        <form>
        { !updated ? (
            <>
              <div>
                <TextInputForm type='password' required={true} title={'Password'} placeholder={''} border={true} value={password1} updateValue={setPassword1} />
                <TextInputForm type='password' required={true} title={'Confirm password'} placeholder={''} border={true} value={password2} updateValue={setPassword2} />
              </div>
              <div className='create-account-btns-container'>
                <button type='submit' className={styles.resetPasswordButton} onClick={handleSubmit}>
                  Save Password
                </button>
              </div>
            </>
            ) : (
              <div>Password Updated</div>
            )}
        </form>
      ) : 
      (
        <div>Searching for user...</div>
      )}
    </div>
  )
}

export default ResetPassword