import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid2';
import Header from "./components/header.jsx";
import Home from "./pages/home.jsx";
import Login from "./pages/login.jsx";
import SignUp from "./pages/signup.jsx";
import BuyNFT from "./pages/buyNFT.jsx";
import ForgotPassword from './pages/forgotPassword.jsx'
import ResetPassword from './pages/resetPassword.jsx'
import VerifyEmail from './pages/verifyEmail.jsx';
import { ContactUs } from "./pages";
import Profile from "./pages/profile.jsx";
import { ProtectedRoute } from './components/protectedRoute'
import { AuthProvider } from './hooks/useAuth'

import styles from "./App.module.css";

function App() {
  return (
    <CssBaseline>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        xs={12}
        className={styles.mainContainer}
      >
        <Router>
          <AuthProvider>
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/login" element={<Login />} />
              <Route path="/contactus" element={<ContactUs />} />
              <Route path="/profile" element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              } />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password/:id/:resetToken" element={<ResetPassword />} />
              <Route path="/nfts" element={<BuyNFT />} />
              <Route path="/verify/:id/:verify" element={<VerifyEmail />} />
            </Routes>
          </AuthProvider>
        </Router>
      </Grid>
    </CssBaseline>
  );
}

export default App;
