import React, {useState} from "react";
import axios from "axios";
import 'react-notifications-component/dist/theme.css';
import dayjs from 'dayjs';
import { Grid2 as Grid } from "@mui/material";
import DatePickerInput from '../components/forms/datepicker.tsx'
import TextInputForm from "../components/forms/textInput.tsx";
import { AgreeCheckBox, RememberMeCheckBox } from "../components/forms/checkbox.tsx";
import { notifyError, notifySuccess } from '../hooks/notifications'
import styles from './signup.module.css'

const SignUp = ({ history }) => {

  const [firstname, setFirstname] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState(dayjs(new Date()));
  const [birthDate, setBirthDate] = useState(dayjs(new Date()));
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [accountCreated, setAccountCreated] = useState(false);
  const [remember, setRemember] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);

  const validateInputs = ({ firstname, surname, email, birthDate, password, password2, agreeTerms }) => {
    let msgs = []

    if (firstname.length==0) msgs.push('firstname is empty')
    if (surname.length==0) msgs.push('surname is empty')
    if (email.length==0) msgs.push('email is empty')
    if (!birthDate || birthDate.length==0) msgs.push('birthDate is empty')
    if (!agreeTerms) msgs.push('You must agree to the terms')
    if (password.length==0) {
      msgs.push('password is empty')
    } else if (password!==password2) {
      msgs.push('Passwords mismatch')
    } else {
      const res = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(password)
      if (!res) msgs.push('Password must be minimum 8 characters, 1 letter, 1 number and 1 special character')
    }

    if (msgs.length){
      msgs.forEach(msg => {
        notifyError(msg, 'The following errors were found')
      })

      return false
    }

    return true
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateInputs({ firstname, surname, email, birthDate, password, password2, agreeTerms })) return false

    try {

      const date_of_birth = dayjs(birthDate).format('YYYY-MM-DD hh:mm:ss')

      const data = {
        firstname,
        surname,
        email,
        date_of_birth,
        remember,
        password,
      };

      const response = await axios.post(`${process.env.REACT_APP_PUBLIC_API}/user`, data);

      localStorage.setItem('responseData', response.data);

      if (response.data.error) {
        return notifyError(response.data.error, 'Register Failed!');
      }

      // Succesfully Registered.
      notifySuccess(`You have succesfully registered! Mr. ${firstname}`, 'Register Success!');
      
      // Navigate to another page
      // navigate('/signin'); // Use navigate instead of push
      setAccountCreated(true);

    } catch (error) {
      const err = error.message || ((error.response) ? error.response.data : error);

      notifyError(err, 'Register Failed!');
    }
  };

  const updateAgreeTerms = async (e) => {
    setAgreeTerms(e.target.checked);
  }

  const updateRemember = async (e) => {
    setRemember(e.target.checked);
  }

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      justifyContent="center"
      xs={12}
      className={styles.signupContainer}
    >
        <h1>Create Account</h1>

        { !accountCreated ? (
          <form>
              <div>
                <TextInputForm required={true} title={"First name"} placeholder={""} border={true} value={firstname} updateValue={setFirstname} />
                <TextInputForm required={true} title={"Surname"} placeholder={""} border={true} value={surname} updateValue={setSurname} />
              </div>
              <div>
                <TextInputForm required={true} title={"Email address"} placeholder={""} border={true} value={email} updateValue={setEmail} />
              </div>
              <div>
                  <DatePickerInput updateValue={setBirthDate}></DatePickerInput>
              </div>
              {/* <div>
                <DatePickerInput date={new Date()} updateValue={setBirthDate}></DatePickerInput>
              </div> */}
              <div>
                <TextInputForm type="password" required={true} title={"Password"} placeholder={""} border={true} value={password} updateValue={setPassword} />
                <TextInputForm type="password" required={true} title={"Confirm password"} placeholder={""} border={true} value={password2} updateValue={setPassword2} />
              </div>
            <div>
              <RememberMeCheckBox className="rememberMe" onChange={updateRemember} />
            </div>
            <div className="Check-Box-Container">
              <AgreeCheckBox className="terms" onChange={updateAgreeTerms}/>
            </div>
            <div className="create-account-btns-container">
              {/*<GoolgeSignInBtn />*/}
              <button type="submit" className={styles.createAccountSubmitBtn} onClick={handleSubmit}>
                Create Account
              </button>
            </div>
          </form>
        ) :
        (
          <div className="create-account-message">
            <p>Your account is created successfully, please check your email for verification link</p>
          </div>
        )}
    </Grid>
  );
};

export default SignUp;
