// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textInput_textInput__NIKNE{
  width: 100%;
  background-color: rgba(255, 255, 255, 0.08) !important;
  color: rgba(255, 255, 255, 1);
  border: 2px solid rgba(13, 76, 165, 0.26);
  border-radius: 43px;
  padding-left: 10px;
  font-size: 16px;
}

.textInput_textInput__NIKNE.textInput_bordered__55Qgc{
  border: 2px solid rgba(255, 255, 255, 1);
  background-color: rgba(255, 255, 255, 0.08) !important;
  border-radius: 43px;
  padding-left: 10px;
  font-size: 16px;
}

.textInput_textInputForm__Mtwmx {
  margin: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/forms/textInput.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,sDAAsD;EACtD,6BAA6B;EAC7B,yCAAyC;EACzC,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,wCAAwC;EACxC,sDAAsD;EACtD,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".textInput{\n  width: 100%;\n  background-color: rgba(255, 255, 255, 0.08) !important;\n  color: rgba(255, 255, 255, 1);\n  border: 2px solid rgba(13, 76, 165, 0.26);\n  border-radius: 43px;\n  padding-left: 10px;\n  font-size: 16px;\n}\n\n.textInput.bordered{\n  border: 2px solid rgba(255, 255, 255, 1);\n  background-color: rgba(255, 255, 255, 0.08) !important;\n  border-radius: 43px;\n  padding-left: 10px;\n  font-size: 16px;\n}\n\n.textInputForm {\n  margin: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textInput": `textInput_textInput__NIKNE`,
	"bordered": `textInput_bordered__55Qgc`,
	"textInputForm": `textInput_textInputForm__Mtwmx`
};
export default ___CSS_LOADER_EXPORT___;
