import React, { useState } from "react";
import TextInputForm from "../components/forms/textInput.tsx";
import { Grid2 as Grid } from "@mui/material";

const ForgotPassword = () => {
  const [email, setEmail] = useState('')

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      const response = await fetch(`${process.env.REACT_APP_PUBLIC_API}/forgotPassword`, {
        method: "POST",
        body: JSON.stringify({ email }),
      })


    } catch(e) {

    }
  }

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      justifyContent="center"
      xs={12}
    >
      <Grid>
        <Grid>
          <div>Forgot Password</div>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
        xs={12}
      >
        <Grid>
          <TextInputForm required="" title="Email" placeholder={"Email"} border={true} value={email} updateValue={setEmail} />
        </Grid>

        <Grid>
          <button type="submit" onClick={handleSubmit}>Reset Password</button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ForgotPassword;