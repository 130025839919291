import React from "react";
import { Grid2 as Grid } from "@mui/material";
import TextInputForm from "../components/forms/textInput.tsx";
import "./contactUs.css";

const ContactUs = () => {
  return (
    <Grid container className="contact-container">
      <Grid container>
        <div className="title-contact-header">Contact Us</div>
      </Grid>
      <Grid container direction="columns">
        <Grid size={{ xs: 12, sm: 6 }}>
          <div className="contact-sub-content">
            We're happy to answer any questions you have or provide you with an
            estimate. <br />
            Just send us a message in the form below with any questions you may
            have.
          </div>
          <form className="contact-form">
            <TextInputForm
              required={false}
              title={"Your name"}
              placeholder={""}
              border={true}
            ></TextInputForm>
            <TextInputForm
              required={false}
              title={"Your email"}
              placeholder={""}
              border={true}
            ></TextInputForm>
            <TextInputForm
              required={false}
              title={"Subject"}
              placeholder={""}
              border={true}
            ></TextInputForm>
            <div className="contact-input-group">
              <div className="sub-title-header-contact">Your message</div>
              <textarea className="message-field"></textarea>
            </div>
            <button type="submit" className="send-button">
              Send
            </button>
          </form>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <div className="intro-paragraph">
            Thank you for your interest in 5th Dimension, a cutting-edge
            cryptocurrency company with a diverse portfolio of projects,
            including a unique NFT art and Cryptocurrency. We value your
            feedback, inquiries, and collaboration opportunities. Feel free to
            reach out to us using the information provided below.
          </div>
          <div className="general-info">
            <div className="title-general-info">General Inquiries</div>
            <br />
            <a
              className="mailto-contact"
              href="mailto:info@5thdimensionclinic.com"
            >
              info@5thdimensionclinic.com
            </a>
          </div>
          <div className="divideline-info" />
          <div className="mailto-colloborate">
            <div className="title-general-info">
              Collaboration Opportunities
            </div>
            <br />
            <a
              className="mailto-contact"
              href="mailto:info@5thdimensionclinic.com"
            >
              info@5thdimensionclinic.com
            </a>
          </div>
          <div className="divideline-info" />
          <div className="mailto-media">
            <div className="title-general-info">Media and Press</div>
            <br />
            <a className="mailto-contact" href="mailto:info@5thdimensionclinic.com">
              info@5thdimensionclinic.com
            </a>
          </div>
          <div className="divideline-info" />
          <div className="mailto-media">
            <div className="title-general-info">Support</div>
            <br />
            <a
              className="mailto-contact"
              href="mailto:info@5thdimensionclinic.com"
            >
              info@5thdimensionclinic.com
            </a>
          </div>
          <div className="divideline-info" />
          <div className="mailto-social-media">
            <div className="title-general-info">Social Media</div>
            <br />
            Stay updated with the latest news, project announcements, and
            engaging content by following us on our social media channels.
          </div>
          <div className="end-para">
            Thank you for considering 5th Dimension as your crypto and NFT
            partner.
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactUs;
