// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datepicker_datePicker__86eGJ {
  width: 100%;
  padding-top: 40px !important;
}

.datepicker_datePicker__86eGJ label {
  color: white !important;
  width: 100%;
}
.datepicker_datePicker__86eGJ input {
  padding: 0 !important;
  border: 2px solid rgba(255, 255, 255, 1);
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 43px !important;
  padding-left: 10px !important;
  margin-left: 10px;
  font-size: 16px;
}

.datepicker_datePicker__86eGJ svg {
  color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/components/forms/datepicker.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,4BAA4B;AAC9B;;AAEA;EACE,uBAAuB;EACvB,WAAW;AACb;AACA;EACE,qBAAqB;EACrB,wCAAwC;EACxC,2CAA2C;EAC3C,8BAA8B;EAC9B,6BAA6B;EAC7B,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".datePicker {\n  width: 100%;\n  padding-top: 40px !important;\n}\n\n.datePicker label {\n  color: white !important;\n  width: 100%;\n}\n.datePicker input {\n  padding: 0 !important;\n  border: 2px solid rgba(255, 255, 255, 1);\n  background-color: rgba(255, 255, 255, 0.08);\n  border-radius: 43px !important;\n  padding-left: 10px !important;\n  margin-left: 10px;\n  font-size: 16px;\n}\n\n.datePicker svg {\n  color: white !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"datePicker": `datepicker_datePicker__86eGJ`
};
export default ___CSS_LOADER_EXPORT___;
