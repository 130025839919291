import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { notifyError } from '../hooks/notifications'

const VerifyEmail = () => {
  const { id, verify: verifyToken } = useParams()
  const [ verified, setVerified] = useState(false)

  useEffect(() => {
    async function verify() {

      const data = {
        userId: +id,
        token: verifyToken,
      }

      try {
        const response = await axios.post(`${process.env.REACT_APP_PUBLIC_API}/verifyEmail`, data)

        if (response.data.status === "true") {
          setVerified(true)
        } else {
          notifyError('Unable to verify email', 'Error')
        }
      } catch(e) {
        notifyError('' + e, 'Error')
      }
    }

    verify() 
  }, [])

  return (
    <div>
      { verified ? (
        <h1>Your all verified. Ready to login</h1>
      ) : (
        <h1>Verifying...</h1>
      )}
    </div>
  )
}

export default VerifyEmail