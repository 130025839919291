// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox_CheckBoxTextLabel__xJbfT {
  color: white;
  margin-top: 20px;
}

.checkbox_checkBox__znS7t svg path {
  color: white !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/forms/checkbox.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".CheckBoxTextLabel {\n  color: white;\n  margin-top: 20px;\n}\n\n.checkBox svg path {\n  color: white !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CheckBoxTextLabel": `checkbox_CheckBoxTextLabel__xJbfT`,
	"checkBox": `checkbox_checkBox__znS7t`
};
export default ___CSS_LOADER_EXPORT___;
