// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_loginContainer__OCqy6 {
  flex-grow: 1;
  color: rgba(255, 255, 255, 1);  
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.login_welcomeHeader__qLSX\\+ {
  font-size: 24px;
  line-height: 29.04px;
}

.login_loginThDimension__7VXVY {
  font-size: 40px;
  line-height: 48.4px;
}

.login_loginSpanTxt__60eZA {
  font-size : 15px;
  line-height: 22.5px;
  margin-top: 15px;
}

.login_checkBoxTextLabel__BIwHR {
  color: white;
  margin-top: 20px;
}

.login_checkBox__sle8m svg path {
  color: white !important;
}

.login_loginForgotPassword__eoFMC {
  text-decoration: none;
  color:rgba(244, 206, 9, 1);
  padding-top: 8px;
  margin-left: 80px;
}

.login_loginButton__As1Gh {
  width: 160px;
  height: 40px;
  border-radius: 24.5px;
  font-family: Poppins;
  font-size: 20px;
  margin-top: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/login.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,6BAA6B;EAC7B,sHAAsH;AACxH;;AAEA;EACE,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;EACrB,0BAA0B;EAC1B,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,qBAAqB;EACrB,oBAAoB;EACpB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".loginContainer {\n  flex-grow: 1;\n  color: rgba(255, 255, 255, 1);  \n  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;\n}\n\n.welcomeHeader {\n  font-size: 24px;\n  line-height: 29.04px;\n}\n\n.loginThDimension {\n  font-size: 40px;\n  line-height: 48.4px;\n}\n\n.loginSpanTxt {\n  font-size : 15px;\n  line-height: 22.5px;\n  margin-top: 15px;\n}\n\n.checkBoxTextLabel {\n  color: white;\n  margin-top: 20px;\n}\n\n.checkBox svg path {\n  color: white !important;\n}\n\n.loginForgotPassword {\n  text-decoration: none;\n  color:rgba(244, 206, 9, 1);\n  padding-top: 8px;\n  margin-left: 80px;\n}\n\n.loginButton {\n  width: 160px;\n  height: 40px;\n  border-radius: 24.5px;\n  font-family: Poppins;\n  font-size: 20px;\n  margin-top: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginContainer": `login_loginContainer__OCqy6`,
	"welcomeHeader": `login_welcomeHeader__qLSX+`,
	"loginThDimension": `login_loginThDimension__7VXVY`,
	"loginSpanTxt": `login_loginSpanTxt__60eZA`,
	"checkBoxTextLabel": `login_checkBoxTextLabel__BIwHR`,
	"checkBox": `login_checkBox__sle8m`,
	"loginForgotPassword": `login_loginForgotPassword__eoFMC`,
	"loginButton": `login_loginButton__As1Gh`
};
export default ___CSS_LOADER_EXPORT___;
