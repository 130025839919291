import React from "react";
// import ModelCanvas from "../components/3D_model";
// import modelPath from "../assets/to_export.glb";

const Home = () => {
    return (
            <div className="home-content">
                {/* <ModelCanvas modelPath={modelPath} initialRotation={[0.9, 0.565, 0]} /> */}
                <h1>3D Model rendering...</h1>
            </div>
      );
};

export default Home;