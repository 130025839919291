// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/backgrounds/bg_home.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App_mainContainer__Zrnhn {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-repeat: repeat;
  min-height: 100vh;
  color: rgba(255, 255, 255, 1);
}
`, "",{"version":3,"sources":["webpack://./src/App.module.css"],"names":[],"mappings":"AAAA;EACE,yDAAyD;EACzD,sBAAsB;EACtB,yBAAyB;EACzB,iBAAiB;EACjB,6BAA6B;AAC/B","sourcesContent":[".mainContainer {\n  background-image: url('./assets/backgrounds/bg_home.png');\n  background-size: cover;\n  background-repeat: repeat;\n  min-height: 100vh;\n  color: rgba(255, 255, 255, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `App_mainContainer__Zrnhn`
};
export default ___CSS_LOADER_EXPORT___;
