import axios from "axios";
import dayjs from 'dayjs';
import React, { useEffect, useState } from "react";
import TextInputForm from "../components/forms/textInput.tsx";
import profileAvatar from "../assets/Profile icon 1 (1).png";
import urlIcon from "../assets/Polygon 1.png";
import profile from "../assets/My_Profile.png";
import editProfile from "../assets/Edit_Profile.png";
import pfp from "../assets/pfp.png";
import { useNavigate } from "react-router-dom";
import DatePickerInput from '../components/forms/datepicker.tsx'
import Wallet from "../components/wallet.jsx";
import { Grid2 as Grid } from "@mui/material";
import { useAuth } from '../hooks/useAuth';
import { notifyError, notifySuccess } from '../hooks/notifications'

import styles from './profile.module.css'

const Profile = () => {

    const [firstname, setFirstname] = useState('');
    const [surname, setSurname] = useState('');
    const [birthDate, setBirthDate] = useState();
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const { user } = useAuth()

    useEffect(() => {
        if (!user) return navigate("/login");
        const { token, id: userId } = user

        axios.get(`${process.env.REACT_APP_PUBLIC_API}/user?id=${userId}`, {
            headers: {
                Authorization: token,
            }
        }).then(res => {
            setFirstname(res.data.firstname);
            setSurname(res.data.surname)
            setBirthDate(dayjs(res.data.date_of_birth))
            setEmail(res.data.email);
        }).catch(err => {
            if (err.response) {
                notifyError("error getting user details");

                return navigate("/login");
            }
        });
    }, []);

    const validateInputs = ({ firstname, surname, email, birthDate }) => {
        let msgs = []

        if (firstname.length==0) msgs.push('firstname is empty')
        if (surname.length==0) msgs.push('surname is empty')
        if (email.length==0) msgs.push('email is empty')
        if (!birthDate || birthDate.length==0) msgs.push('birthDate is empty')

        if (msgs.length){
            msgs.forEach(msg => {
                notifyError(msg, 'The following errors were found')
            })

            return false
        }

        return true
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { token, id: userId } = user
        if (!validateInputs({ firstname, surname, email, birthDate })) return false

        try {

            const date_of_birth = dayjs(birthDate).format('YYYY-MM-DD hh:mm:ss')
            const body = {
                firstname,
                surname,
                email,
                date_of_birth,
            }
            const response = await fetch(`${process.env.REACT_APP_PUBLIC_API}/user?id=${userId}`, {
                method: "PUT",
                body: JSON.stringify(body),
                headers: {
                    Authorization: token,
                },
            })
            console.log('response: ', response)
            console.log('response.json: ', await response.json())
            if (!response.ok) {
                return notifyError(response.body.error, 'Saving Changes Failed!')
            }

            // Succesfully Registered.
            notifySuccess(`Changs updated successfully`)

        } catch (error) {
          const err = error.message || ((error.response) ? error.response.data : error)

          notifyError(err, 'Saving Changes Failed!')
        }
    };

    return (
        <Grid container direction="column" justify="center" alignItems="center" style={{width: '100%'}}>
            <Grid container className={styles.profileHeader} justify="space-between" alignItems="center">
                <Grid className={styles.profileWallet} sx={{flexGrow: 1}}>
                    <div className={styles.leftSidebar}>
                        <img className={styles.profileAvatar} src={profileAvatar} alt="Avatar" />
                        <div className={styles.subheaderWallet}>My Profile</div>
                    </div>
                    <div className={styles.subtitleWallet}>Crypto Wallet <img className={styles.urlIcon} src={urlIcon} alt=">" /><span className={styles.myProfile}> My Profile</span></div>
                </Grid>

                <Grid className={styles.balanceInfo}>
                    <Wallet></Wallet>
                </Grid>
            </Grid>
            <Grid container direction="column" className={styles.profileMainContent}>
                <Grid container className="profile-nav">
                    <button className={`${styles.profileTab} ${styles.live}`} >
                        <img className="profile-Icon" src={profile} alt="My_Profile" />
                        <div className={styles.detailText}> Profile </div>
                    </button>
                    <button className={styles.profileTab} >
                        <img className="profile-Icon" src={editProfile} alt="editProfile" />
                        <div className={styles.detailText}> Update Password </div>
                    </button>
                </Grid>
                <Grid container className={styles.profileDetails}>
                    <Grid className={styles.userInfo}>
                        <img className="user-photo" src={pfp} alt="My_Profile" />
                        <div className={styles.privateInfos}>
                            <div className={styles.username}>{firstname} {surname}</div>
                            <div className={styles.userEmail}>{email}</div>
                            <div className={styles.userNation}>United States</div>
                        </div>
                    </Grid>
                    <Grid>
                        <form className={styles.profileForm}>
                            <div className={styles.inputGroup}>
                                <TextInputForm value={firstname} required={false} title={"Firstname"} placeholder={"Firstname"} updateValue={setFirstname} border={true} />
                                <TextInputForm value={surname} required={false} title={"Surname"} placeholder={"Surname"} updateValue={setSurname} border={true} />
                                <div>
                                    <DatePickerInput date={birthDate} updateValue={setBirthDate}></DatePickerInput>
                                </div>
                                <TextInputForm value={email} type="text" required={false} title={"Email"} placeholder={"Email"} updateValue={setEmail} border={true} />
                            </div>
                            <button type="submit" className={styles.createAccountSubmitBtn} onClick={handleSubmit}>
                                Save Changes
                            </button>
                        </form>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default Profile;
