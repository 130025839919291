import { Store } from 'react-notifications-component'

export const notifyError = (message, title) => {
  Store.addNotification({
    title,
    message,
    type: "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  })
}

export const notifySuccess = (message, title) => {
  Store.addNotification({
    title,
    message,
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  })
}