import React, { useState, useEffect } from "react"
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import styles from './datepicker.module.css'

const DatePickerInput = ({ date, updateValue }) => {
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker label="Date of Birth"  className={styles.datePicker} value={date} onChange={(newValue) => {
            updateValue(dayjs(newValue))
          }}/>
      </LocalizationProvider>
    </div>
  )
}

export default DatePickerInput
