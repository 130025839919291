import MetaMaskSDK from '@metamask/sdk'
import dollar from "../assets/OBJECTS.png";
import { ethers, formatEther } from 'ethers'
import { useEffect, useState } from 'react'

import styles from './wallet.module.css'

const CHAIN_ID = 0xaa36a7
const MMSDK = new MetaMaskSDK()

const mipAddress = '0xc8dc090656edee53b1b335168d1e857e8b1c70f8'
const { abi } = require('../artifacts/contracts/MIP.sol/MIP.json')
let mip, ethereum

function Wallet () {
  const [ethBalance, setEthBalance] = useState(0)
  const [mipBalance, setMipBalance] = useState(0)
  const [onRightNetwork, setOnRightNetwork] = useState(false)
  const [accounts, setAccounts] = useState([])
  const [isConnected, setIsConnected] = useState(
    accounts && accounts.length > 0
  )

  useEffect(() => {        
    async function getAccounts() {
      console.log('getting accounts')
      try {
        const _accounts = await MMSDK.connect()
        ethereum = MMSDK.getProvider()
        const provider = new ethers.BrowserProvider(ethereum)

        mip = new ethers.Contract(
          mipAddress,
          abi,
          await provider.getSigner(),
        )

        setAccounts(_accounts)
        setIsConnected(accounts && accounts.length > 0)
      } catch(e) {
        console.log('caught the error: ', e)
      }
    }

    getAccounts()
  }, [])

  // useEffect(() => {

  // }, [isConnected])

  useEffect(() => {
    if (accounts.length) updateBalances()
  }, [accounts])

  // async function handleNetwork() {
  //   const chainId = await ethereum.request({
  //     method: 'eth_chainId',
  //   })

  //   if (chainId!=CHAIN_ID) {
  //     setOnRightNetwork(false)
  //   } else {
  //     setOnRightNetwork(true)
  //   }
  // }

  async function updateBalances() {
    if (accounts.length > 0) {
      console.log('updating balances')
      const mipBalance = await mip.balanceOf(accounts[0])
      setMipBalance(mipBalance.toString())

      const ethBalance = await ethereum.request({
        method: 'eth_getBalance',
        params: [accounts[0], 'latest'],
      })
      setEthBalance(Math.round(formatEther(ethBalance) * 1e4) / 1e4)

      console.log('mips: ', mipBalance.toString())
      console.log('eth: ', Math.round(formatEther(ethBalance) * 1e4) / 1e4)
    }
  }

  return (
    <div>
      <div className={styles.availableBalanceText}>
          <img src={dollar} alt="objects" className={styles.dollarIcon} />
          <div>Available Balance</div>
      </div>
      <div className={styles.ETHBalance}><span className={styles.balance}>{ethBalance}</span> <span className={styles.token}>ETH</span></div>
      <div className={styles.MIPsBalance}><span className={styles.balance}>{mipBalance}</span> <span className={styles.token}>5th Dim NFTs</span></div>
    </div>
  )
}

export default Wallet