import React from "react";
import { Link } from "react-router-dom";
import { AppBar, Box, Toolbar } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useAuth } from '../hooks/useAuth';

import photo from "../assets/icons/profile-default.svg";
import logo from "../assets/logo.svg";
import styles from './header.module.css';

const Header = () => {
  const { user, logout } = useAuth()

  const handleLogout = () => {
    logout()
  };

  return (
    <Box className={styles.appHeader}>
      <AppBar position="static">
        <Toolbar>
          <Box className={styles.headerLogo}>
            <Link to="/">
              <img src={logo} alt="Logo" />
            </Link>
          </Box>
          <Box sx={{ flexGrow: 1}} />
          <Box className={styles.headerLinks} size="6" xs="12">
            <Grid container justify="center" alignItems="center" justifyContent="flex-end">
              <Grid>
                <Link to="/contactus">Contact Us</Link>
              </Grid>
                {!user &&
                  <>
                    <Grid>
                      <Link to="/signup">Sign Up</Link>
                    </Grid>
                    <Grid>
                      <Link to="/login">
                        <span className = "signInButton">
                          <span className = "signIn_user">
                              <img src={photo} alt="The users profile image"/>
                          </span>
                          <span className = "signInTxt">
                              Sign In
                          </span>
                        </span>
                      </Link>
                     </Grid>
                  </>
                }
              {user && (
                <>
                <Grid>
                 <Link to="/profile">Profile</Link>
                </Grid>
                <Grid>
                 <Link to="/nfts">Buy NFT</Link>
                </Grid>
                <Grid>
                 <button onClick={handleLogout} className={styles.logoutButton}>
                   Logout
                 </button>
                </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
