import React from "react";

import styles from "./textInput.module.css"

const TextInputForm = ({ required, title, placeholder, border, value, updateValue, type='text' }) => {
  const handleChange = (e) => {
    updateValue(e.target.value)
  };

  return (
    <div className={styles.textInputForm}>
      <label className="field-title">
        {title}
        {required && <span className="required-field">(Required)</span>}
      </label>

      <input
        type={type}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        className={`${styles.textInput} ${border ? styles.bordered : ""}`}
        required={required}
      ></input>
    </div>
  );
};

export default TextInputForm;