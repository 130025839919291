import React, { useState } from "react";
import { Checkbox, Grid2 as Grid } from "@mui/material";
import TextInputForm from "../components/forms/textInput.tsx";
import { Link } from "react-router-dom";
import { useAuth } from '../hooks/useAuth';
import { notifyError, notifySuccess } from '../hooks/notifications'

import 'react-notifications-component/dist/theme.css';
import styles from './login.module.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const { login } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    const body = JSON.stringify({
      email,
      password,
      remember: remember ? true : false,
    })

    try {
      const response = await fetch(`${process.env.REACT_APP_PUBLIC_API}/auth`, {
        method: "POST",
        body,
      })

      const data = await response.json()
      if (data.error) {
        return notifyError('Invalid Login');
      }

      const {token, id} = data
      await login({ token, id })

      // Success Notification
      notifySuccess();

      // Navigate to the profile page, force complete re-render
      window.location.href = '/profile';

    } catch (error) {
      console.error(error);

      // Display login error notification
      notifyError(error.response?.data?.message || "Login failed.");
    }
  };

  const updateRemember = async (e) => {
    setRemember(e.target.checked);
  }

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      justifyContent="center"
      xs={12}
      className={styles.loginContainer}
    >
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
        xs={12}
        className={styles.loginContainer}
      >
        <Grid item xs={12} spacing={2} className={styles.loginContainer}>
          <div className={styles.welcomeHeader}>Welcome to</div>
        </Grid>
        <Grid item xs={12} spacing={2} className={styles.loginContainer}>
          <div className={styles.loginThDimension}>5TH DIMENSION</div>
        </Grid>
        <Grid item xs={12} spacing={2} className={styles.loginContainer}>
          <div className={styles.loginSpanTxt}><span>Log in </span>now to explore the 5th Dimension.</div>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
        xs={12}
        className={styles.loginContainer}
      >
        <Grid item xs={12} spacing={2} className={styles.loginContainer}>
            <TextInputForm required="" title="Email" placeholder={"Email"} border={true} value={email} updateValue={setEmail} />
            <TextInputForm type="password" required="" title="Password" placeholder={"Password"} border={true} value={password} updateValue={setPassword} />
        </Grid>

        <Grid item xs={12} spacing={2} className={styles.loginContainer}>
          <Grid container className={styles.loginContainer} justifyContent="space-between">
            <Grid item>
              <Checkbox className={styles.checkBox} onChange={updateRemember} />
              <label className={styles.checkBoxTextLabel}>Remember me</label>
            </Grid>
            <Grid item><Link to="/forgot-password" className={styles.loginForgotPassword}>Forgot Password</Link></Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} spacing={2} className={styles.loginContainer}>
          <button type="submit" className={styles.loginButton} onClick={handleSubmit}>Login</button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
