// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resetPassword_resetPasswordButton__qVHIr {
  border: none;
  color: black;
  background-color: #ffffff;
  width: 250px;
  height: 31.5px;
  text-align: center;
  padding-top: 7.5px;
  border-radius: 4px;
  margin-top: 20px;
  margin-left: 5%;
  font-size: 16px;
  cursor: pointer;
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/resetPassword.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,cAAc;EACd,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,eAAe;EACf,eAAe;EACf,aAAa;AACf","sourcesContent":[".resetPasswordButton {\n  border: none;\n  color: black;\n  background-color: #ffffff;\n  width: 250px;\n  height: 31.5px;\n  text-align: center;\n  padding-top: 7.5px;\n  border-radius: 4px;\n  margin-top: 20px;\n  margin-left: 5%;\n  font-size: 16px;\n  cursor: pointer;\n  outline: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resetPasswordButton": `resetPassword_resetPasswordButton__qVHIr`
};
export default ___CSS_LOADER_EXPORT___;
