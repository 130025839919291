// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_appHeader__PlaTK {
  width: 100%;
}
.header_appHeader__PlaTK header{
  background: none;
  font-size: calc(10px + 2vmin);
  color: white;
}
.header_headerLinks__OL2Fx {
	width: 100%;
	text-align: right;
}

.header_headerLinks__OL2Fx a {
  text-decoration: none;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: rgba(255, 255, 255, 1);
  font-size: 24px;
  padding-right: 20px;
}

.header_headerLogo__actUh {
  transform: scale(0.8);
  margin-left: 1%;
}

.header_logoutButton__0fYw0 {
  border: 2px solid rgba(255, 255, 255, 1);
  /* display: flex; */
  border-radius: 76px;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}

.header_signInButton__VFjaX {
  border: 2px solid rgba(255, 255, 255, 1);
  display: flex; 
  border-radius: 76px;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}

.header_signInTxt__E9v4g {
  text-decoration: none;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: rgba(255, 255, 255, 1);
  font-size: 24px;
}

`, "",{"version":3,"sources":["webpack://./src/components/header.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,6BAA6B;EAC7B,YAAY;AACd;AACA;CACC,WAAW;CACX,iBAAiB;AAClB;;AAEA;EACE,qBAAqB;EACrB,sHAAsH;EACtH,6BAA6B;EAC7B,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,wCAAwC;EACxC,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;EAC9B,YAAY;AACd;;AAEA;EACE,wCAAwC;EACxC,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;EAC9B,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,sHAAsH;EACtH,6BAA6B;EAC7B,eAAe;AACjB","sourcesContent":[".appHeader {\n  width: 100%;\n}\n.appHeader header{\n  background: none;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n.headerLinks {\n\twidth: 100%;\n\ttext-align: right;\n}\n\n.headerLinks a {\n  text-decoration: none;\n  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;\n  color: rgba(255, 255, 255, 1);\n  font-size: 24px;\n  padding-right: 20px;\n}\n\n.headerLogo {\n  transform: scale(0.8);\n  margin-left: 1%;\n}\n\n.logoutButton {\n  border: 2px solid rgba(255, 255, 255, 1);\n  /* display: flex; */\n  border-radius: 76px;\n  align-items: center;\n  justify-content: space-between;\n  padding: 5px;\n}\n\n.signInButton {\n  border: 2px solid rgba(255, 255, 255, 1);\n  display: flex; \n  border-radius: 76px;\n  align-items: center;\n  justify-content: space-between;\n  padding: 5px;\n}\n\n.signInTxt {\n  text-decoration: none;\n  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;\n  color: rgba(255, 255, 255, 1);\n  font-size: 24px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appHeader": `header_appHeader__PlaTK`,
	"headerLinks": `header_headerLinks__OL2Fx`,
	"headerLogo": `header_headerLogo__actUh`,
	"logoutButton": `header_logoutButton__0fYw0`,
	"signInButton": `header_signInButton__VFjaX`,
	"signInTxt": `header_signInTxt__E9v4g`
};
export default ___CSS_LOADER_EXPORT___;
